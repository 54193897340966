import { t } from '../utils/index.mjs';

const color = {
  color: t.colors("color"),
  textColor: t.colors("color"),
  fill: t.colors("fill"),
  stroke: t.colors("stroke"),
  accentColor: t.colors("accentColor"),
  textFillColor: t.colors("textFillColor")
};

export { color };
